import moment from 'moment'

import * as bookingv1 from '../proto/booking/v1/booking_pb'
import * as orderv1 from '../proto/order/v1/order_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

import { Ordering, eq, gt, lt, makeOrderingFn } from '../helpers/sorting'

import CSS from 'csstype'

const timeFormat = 'HH:mm'
const dateFormat = 'YYYY-MM-DD'
const dateAndTimeFormat = 'YYYY-MM-DD HH:mm'

const RFC3339Regex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/g

export function validateTimestamp(timestamp: Timestamp | undefined): string {
  if (!timestamp) {
    return ''
  }
  const currentDate = moment.unix(timestamp.getSeconds())
  if (currentDate.format(dateAndTimeFormat) === '0001-01-01 00:00') {
    return ''
  } else if (currentDate.format(dateFormat) === '0001-01-01') {
    return currentDate.format(timeFormat)
  } else if (currentDate.format(timeFormat) === '00:00') {
    return currentDate.format(dateFormat)
  }
  return currentDate.format(dateAndTimeFormat)
}

export function formatTimestamp(
  timestamp: Timestamp | undefined,
  format:
    | 'YYYY-MM-DD HH:mm'
    | 'YYYY-MM-DD'
    | 'YYYY-MM'
    | 'MMM YYYY'
    | 'HH:mm'
    | 'MMM D'
    | 'DD/MM'
    | 'L LT'
    | 'L'
    | 'LT',
  defaultText: string,
): string {
  if (!timestamp) {
    return defaultText
  }
  return moment.unix(timestamp.getSeconds()).format(format)
}

export function formatTimeRange(
  start: Timestamp | undefined,
  end: Timestamp | undefined,
  defaultText: string,
): string {
  if (!start || !end) {
    return defaultText
  }
  const s = moment.unix(start.getSeconds())
  const e = moment.unix(end.getSeconds())
  return s.format(timeFormat) + ' - ' + e.format(timeFormat)
}

export function fromNow(timestamp: Timestamp): string {
  return moment(timestamp.toDate()).fromNow()
}

export function diffTimeInTransit(
  from: Timestamp | undefined,
  to: Timestamp | undefined,
  defaultText: string,
): string {
  if (!from || !to) {
    return defaultText
  }
  const range = timestampToMoment(to).diff(timestampToMoment(from).format(dateFormat), 'days')
  if (range > 1) {
    return `${range} days`
  } else {
    return `${range} day`
  }
}

// isValidPickup check if the date isn't in the future.
export function isValidPickup(
  pickupDate: Timestamp | undefined,
  pickupTime: Timestamp | undefined,
): boolean {
  const compareDate = timestampToMoment(pickupDate)
    .set({ hour: 0, minute: 0 })
    .diff(moment().format(dateFormat), 'days')
  if (compareDate < 0) {
    return true
  } else if (compareDate === 0) {
    if (!pickupTime) {
      return moment().set({ hour: 16, minute: 0 }).isBefore(timestampToMoment(pickupDate))
    }
    return moment()
      .set({ hour: pickupTime.toDate().getHours(), minute: pickupTime.toDate().getMinutes() })
      .isBefore(moment())
  }
  return false
}

export function timestampToMoment(ts?: Timestamp): moment.Moment {
  if (!ts) {
    return moment()
  }
  return moment.unix(ts.getSeconds())
}

export function fromMoment(m: moment.Moment): Timestamp {
  const ts = new Timestamp()
  ts.setSeconds(m.unix())
  ts.setNanos(m.millisecond() * 1e6)
  return ts
}

// Accepts undefined for the sake of interrop with pb objects and higher order functions
export function sortFn<T>(getDate: (t: T) => moment.Moment | undefined): (t1: T, t2: T) => number {
  return (t1, t2) => makeOrderingFn(getDate, compareDate)(t1, t2).jsComparisonValue
}

// Accepts undefined for the sake of interrop with pb objects and higher order functions
export function compareDate(fst: moment.Moment, snd: moment.Moment): Ordering {
  if (fst.year() < snd.year()) {
    return lt
  }
  if (fst.year() > snd.year()) {
    return gt
  }
  if (fst.month() < snd.month()) {
    return lt
  }
  if (fst.month() > snd.month()) {
    return gt
  }
  if (fst.date() < snd.date()) {
    return lt
  }
  if (fst.date() > snd.date()) {
    return gt
  }
  return eq
}

export function displayTimePopoverText(
  firstDate: Timestamp,
  usage: 'ARRIVAL' | 'DEPARTURE' | 'ORDER',
  bookingStatus?: bookingv1.Booking.Status,
): string {
  const fstDt = timestampToMoment(firstDate)
  const today = moment.utc().startOf('day')
  if (fstDt && bookingStatus !== bookingv1.Booking.Status.DELIVERED) {
    switch (compareDate(fstDt, today).tag) {
      case 'EQ':
        return 'In time'
      case 'LT':
        return `Delayed by ${
          moment.duration(fstDt.diff(today)).asDays() < -1
            ? `${Math.floor(-1 * moment.duration(fstDt.diff(today)).asDays())} days`
            : `${Math.floor(-1 * moment.duration(fstDt.diff(today)).asDays())} day`
        }`
      case 'GT':
        switch (usage) {
          case 'ARRIVAL':
            return `Arrives in ${
              moment.duration(fstDt.diff(today)).asDays() > 1
                ? `${Math.floor(moment.duration(fstDt.diff(today)).asDays())} days`
                : `${Math.floor(moment.duration(fstDt.diff(today)).asDays())} day`
            }`
          case 'DEPARTURE':
            return `Departs in ${
              moment.duration(fstDt.diff(today)).asDays() > 1
                ? `${Math.floor(moment.duration(fstDt.diff(today)).asDays())} days`
                : `${Math.floor(moment.duration(fstDt.diff(today)).asDays())} day`
            }`
          case 'ORDER':
            return `Order completed in ${
              moment.duration(fstDt.diff(today)).asDays() > 1
                ? `${Math.floor(moment.duration(fstDt.diff(today)).asDays())} days`
                : `${Math.floor(moment.duration(fstDt.diff(today)).asDays())} day`
            }`
        }
    }
  }
  return ''
}

export function displayTimeStyle(
  firstDate: Timestamp,
  bookingStatus?: bookingv1.Booking.Status,
): CSS.Properties {
  const fstDt = timestampToMoment(firstDate)
  const today = moment.utc().startOf('day')
  if (
    fstDt &&
    compareDate(fstDt, today).tag === 'LT' &&
    bookingStatus !== bookingv1.Booking.Status.DELIVERED
  ) {
    return { fontWeight: 'bold', color: '#fa5a50' }
  }
  return {}
}

export function displayActionDate(actionDate: Timestamp): CSS.Properties {
  const aDT = timestampToMoment(actionDate)
  const today = moment.utc().startOf('day')
  if (aDT && compareDate(aDT, today).tag === 'EQ') {
    return { fontWeight: 'bold', color: '#000000' }
  } else if (aDT && compareDate(aDT, today).tag === 'LT') {
    return { fontWeight: 'bold', color: '#fa5a50' }
  }
  return {}
}

export function earliestPickupBookingDate(
  ordertypes: ReadonlyArray<orderv1.OrderType>,
  pickupDateOffset: number,
  disableWeekends = true,
) {
  return (current: moment.Moment | null) => {
    if (!current) {
      return true
    }
    const earliestBookings: number[] = ordertypes.map((o) =>
      o === orderv1.OrderType.PURCHASE ? 1 : pickupDateOffset,
    )
    if (earliestBookings.length === 0) {
      earliestBookings.push(pickupDateOffset)
    }
    const earliestBooking = Math.max(...earliestBookings)
    const disabledDueToWeekend = disableWeekends && current.isoWeekday() > 5
    const tooEarly = current < addWeekDays(moment().startOf('day'), earliestBooking)
    return tooEarly || disabledDueToWeekend
  }
}

export function earliestDeliveryBookingDate(
  desiredPickupDate: moment.Moment,
  disableWeekends = true,
) {
  return (current: moment.Moment) => {
    const disabledDueToWeekend = disableWeekends && current.isoWeekday() > 5
    return current && (current < desiredPickupDate || disabledDueToWeekend)
  }
}

export function addWeekDays(date: moment.Moment, days: number) {
  while (days > 0) {
    date.add(1, 'days')
    // decrease "days" only if it's a weekday.
    if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
      days--
    }
  }
  return date
}

// Receive a strings and format to local time if necessary.
export function formatStringDatesToLocal(description: string, includeTime: boolean): string {
  const dates = description.match(RFC3339Regex)
  if (!dates) {
    return description
  }
  dates.forEach((d) => {
    const convertDate = moment(d).unix()
    description = includeTime
      ? description.replace(d, moment.unix(convertDate).format(dateAndTimeFormat))
      : description.replace(d, moment.unix(convertDate).format(dateFormat))
    //description = description.replace(d, moment.unix(convertDate).format(dateAndTimeFormat))
  })
  return description
}

export function timestampToString(timestamp: Timestamp): string {
  return moment.unix(timestamp.getSeconds()).calendar(null, {
    lastDay: '[Yesterday at] HH:mm',
    sameDay: '[Today at] HH:mm ',
    sameElse: 'YYYY-MM-DD HH:mm',
  })
}

/**
 * Time has to have length of 5 e.g 00:00 to be considered valid.
 */
export function isValidTime(time: string): boolean {
  const dateTime = moment(moment().format('YYYY-MM-DDT') + time)
  return time?.length === 5 && dateTime.isValid() ? true : false
}

export function dateTimeAisBeforeTimeB(
  dateTimeA: moment.Moment,
  dateTimeB: moment.Moment,
): boolean {
  return dateTimeA.isBefore(dateTimeB, 'minutes')
}

export function timeAisBeforeTimeB(timeA: string, timeB: string): boolean {
  const dateTimeA = createDateTimeFromTime(timeA)
  const dateTimeB = createDateTimeFromTime(timeB)
  return dateTimeA.isBefore(dateTimeB, 'seconds')
}

export function timeIsBetween(time: string, timeSpanStart: string, timeSpanEnd: string): boolean {
  const dateTime = createDateTimeFromTime(time)
  const dateTimeA = createDateTimeFromTime(timeSpanStart)
  const dateTimeB = createDateTimeFromTime(timeSpanEnd)
  return dateTime.isBetween(dateTimeA, dateTimeB, 'minute', '[]')
}

export function dateTimeAisBeforeDateTimeB(dateTimeA: string, dateTimeB: string): boolean {
  const dateTime1 = moment(dateTimeA)
  const dateTime2 = moment(dateTimeB)
  return dateTime1.isBefore(dateTime2, 'seconds')
}

export function createDateTimeFromTime(time: string): moment.Moment {
  return moment(moment().format('YYYY-MM-DDT') + time)
}

export function createMomentFromDateAndTime(
  date: moment.Moment | string | null,
  time: string | null,
): moment.Moment {
  return moment(moment(date).format('YYYY-MM-DD') + 'T' + time)
}

export function createTimeWithAddedHours(
  time: string,
  addAmount: number,
  unit: 'minute' | 'hour',
): string {
  return moment(moment().format('YYYY-MM-DDT') + time)
    .add(addAmount, unit)
    .format('HH:mm')
}

export const formatYear = (dateTime: moment.Moment | string): string => {
  return moment(dateTime).format('YYYY-MM-DD')
}

export const formatTime = (dateTime: moment.Moment | string): string => {
  return moment(dateTime).format('HH:mm')
}

export const createDateFromString = (date: string): moment.Moment => {
  return moment(date)
}

export const newDate = (date?: moment.Moment | string): moment.Moment => {
  return date ? moment(date) : moment()
}
export const newDateAsString = (date?: moment.Moment | string): string => {
  return date ? moment(date).format() : moment().format()
}

const formateDateTime = (dateTime: string): string => moment(dateTime).format(dateAndTimeFormat)
const formatDate = (dateTime: string): string => moment(dateTime).format(dateFormat)

export { formateDateTime, formatDate }
